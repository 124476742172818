@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}

.services-container .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.services-container .col-md-4 {
  flex-basis: calc(33.33% - 20px); /* 33.33% width with spacing */
  margin-bottom: 20px;
}

.services-container .col-md-4:last-child {
  margin-bottom: 0;
}
